import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'

export const loadPages = (instance, journalId) => {
    instance.setState({
        ...instance.state,
        loadingPages: true
    })

    let criteria = {
        journal: journalId
    }

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/pages",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data:  {
            offset: 0,
            limit: 50,
            criteria: criteria,
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleLoadPagesResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadPagesResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingPages: false,
                pages: response.data.data.pages
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loadingPages: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}