import React from 'react'
import PagesWorker from './pages'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import Emitter, { EmitterEvents } from '../../../services/emitter'
import { API_ENDPOINT } from '../../../config'
import iconEdit from '../../../assets/icons/edit-black.svg'
import iconDelete from '../../../assets/icons/delete-black.svg'
import Pagination from '../../../components/pagination/pagination.component'

class Pages extends React.Component {

    constructor() {
        super()
        this.pagesWorker = PagesWorker(this)
        this.state = this.pagesWorker.getDefaultState()
    }

    componentDidMount() {
        this.pagesWorker.loadPages()
        Emitter.on(EmitterEvents.SEARCH_TEXT_ENTERED, filter => this.setState({
            ...this.state,
            filter: filter
        }, this.pagesWorker.loadPages))
    }

    componentWillUnmount(){
        Emitter.off(EmitterEvents.SEARCH_TEXT_ENTERED)
    }

    onDelete = id => this.pagesWorker.deletePage(id)

    getUrl = plan => {
        return API_ENDPOINT + "/journal/" + plan.journal.url.toLowerCase() + '/' + plan.url
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.pagesWorker.loadPages())
    }

    render() {
        return (
            <div className="pages">
                {
                    (this.state.loading || this.state.deleting) && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/journal/pages/create">New Page</Link>
                    <div className="header">
                        <h4>Pages</h4>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Journal</th>
                                <th>Title</th>
                                <th>Url</th>
                                <th>Parent</th>
                                <th>Order</th>
                                <th>Updated</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.pages.map((page, key) => <tr key={key}>
                                    <td>{page.journal.url.toUpperCase()}</td>
                                    <td>{page.title}</td>
                                    <td><a href={this.getUrl(page)} rel="noopener noreferrer" target="_blank">{this.getUrl(page)}</a></td>
                                    <td>{page.parent !== null && page.parent.title}</td>
                                    <td>{page.ordering}</td>
                                    <td>{new Date(page.updated).toString("dd MMM, yyyy")}</td>
                                    <td>
                                        <div className="actions">
                                            <img alt="" src={iconEdit} onClick={() => this.props.history.push('/journal/pages/edit/' + page.id)} />
                                            <img alt="" src={iconDelete} onClick={() => this.onDelete(page.id)} />
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(Pages)