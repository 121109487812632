import React from 'react'
import EditPageWorker from './edit'
import { Link, withRouter } from 'react-router-dom'
import PageForm from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import './edit.styles.scss'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'

class EditPage extends React.Component {

    constructor(props) {
        super(props)
        this.editPageWorker = EditPageWorker(this)
        this.state = this.editPageWorker.getDefaultState()
    }

    componentDidMount(){
        this.editPageWorker.loadJournals()
        this.editPageWorker.loadPage()
    }

    onJournalChange = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        }, () => this.editPageWorker.loadPages(this.state.journalId))
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.editPageWorker.isValid() && this.editPageWorker.updatePage()
    }

    onCancel = () => this.props.history.push('/journal/pages')

    render() {
        return (
            <div id="createPage" className="edit-page">
                {
                    (this.state.loadingJournals || this.state.loadingPage || this.state.loadingPages) && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/journal/pages">Pages</Link>
                    <div className="header">
                        <h4>Edit Page</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <PageForm {...this.state} action="Save Page" onJournalChange={this.onJournalChange} onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}/>
                </div>
            </div>
        )
    }
}

export default withRouter(EditPage)