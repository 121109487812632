import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'

const getDefaultState = () => {
    return {
        loading: false,
        deleting: false,
        pages: [],
        filter: "",
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0
    }
}

const loadPages = instance => {
    instance.setState({ ...instance.state, loading: true })

    let criteria = {}
    if (instance.state.filter.length > 0) {
        criteria = {
            title: instance.state.filter
        }
    }

    if(typeof instance.props.match.params.id != undefined){
        criteria.journal = instance.props.match.params.id
    }

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/pages",
        headers : {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            offset: (instance.state.currentPage - 1) * instance.state.pageSize,
            limit: instance.state.pageSize,
            criteria: criteria,
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleLoadPagesResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadPagesResponse = (instance, response) => {
    if (response.status === 200 && response.data.status === 200) {
        instance.setState({
            ...instance.state,
            loading: false,
            pages: response.data.data.pages,
            total: response.data.data.size
        })
    }
    else if (response.data.status === 403) {
        instance.props.history.push('/auth/login')
    }
}

const deletePage = (instance, pageId) => {
    instance.setState({ ...instance.state, deleting: true })

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/page/delete",
        headers : {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            id: pageId,
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleDeletePageResponse(instance, response, pageId))
    .catch(error => alert(error))
}

const handleDeletePageResponse = (instance, response, pageId) => {
    if (response.status === 200 && response.data.status === 200) {
        instance.setState({
            ...instance.state,
            deleting: false,
            pages: instance.state.pages.filter(page => page.id !== pageId)
        })
    }
    else if (response.data.status === 403) {
        instance.props.history.push('/auth/login')
    }
}

const PagesWorker = instance => {
    return {
        loadPages: () => loadPages(instance),
        deletePage: (pageId) => deletePage(instance, pageId),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default PagesWorker